import React, { useState, useEffect } from 'react'
import { globalHistory } from '@reach/router'
import { StaticQuery, graphql, navigate } from 'gatsby'
import LinkWrap from 'components/LinkWrap'
import LineLink from 'components/LineLink'
import ButtonLink from 'components/ButtonLink'
import IconExpand from 'components/IconExpand'
// import Social from 'components/Social'
import Inview from 'components/Inview'
import LogoSvg from 'assets/svg/vi/logo.svg'
import { LocaleContext } from 'gatsby-theme-i18n'
import * as styles from './styles.module.scss'
import { useLocation } from '@reach/router'

const Component = ({ data, layoutProps }) => {
  const locale = React.useContext(LocaleContext)

  const {
    isMenuOpen,
    isHeaderUp,
    toggleIsMenuOpen,
    isHeaderDark,
    setIsHeaderBgOn,
  } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''
  const headerUpClass = isHeaderUp ? styles.headerUp : ''
  const headerDarkClass = isHeaderDark ? styles.headerThemeDark : ''

  let menu = data.menus.nodes[0].frontmatter[locale]

  const locations = data?.locations?.nodes
  const locationsLocale = locations?.map((item) => item.frontmatter[locale])
  const productCats = data?.productCats?.nodes
  const productCatsLocale = productCats?.map((item) => item.frontmatter[locale])

  let pages = data.pages.nodes
  pages = pages.map((page) => page.frontmatter[locale])
  menu.main.forEach((item) => {
    item.data = pages.find((page) => page.slug === item.page)
    item.submenu?.forEach((subitem) => {
      subitem.data = pages.find((page) => page.slug === subitem.page)
    })
    if (item.page === 'locations') {
      item.submenu = locationsLocale.map((location) => ({
        data: {
          path: `/locations/${location.slug}`,
          slug: location.slug,
          title: location.title,
        },
      }))
    }
    if (item.page === 'products') {
      item.submenu = productCatsLocale.map((productCat) => ({
        data: {
          path: `/products/${productCat.slug}`,
          slug: productCat.slug,
          title: productCat.title,
        },
      }))
    }
  })
  const lastItemStagger = (menu.main.length + 1) * 50 + 100

  return (
    <>
      <div
        className={`${styles.overlay} ${menuOpenClass} `}
        onClick={toggleIsMenuOpen}
      />
      <LinkWrap to='/' className={`${styles.logoMobile} ${menuOpenClass}`}>
        <LogoSvg />
      </LinkWrap>
      <Inview
        className={`menu ${styles.menu} ${menuOpenClass} ${headerDarkClass} ${headerUpClass}`}
      >
        <div
          className={styles.inner}
          onMouseEnter={() => setIsHeaderBgOn(true)}
          onMouseLeave={() => setIsHeaderBgOn(false)}
        >
          <div className={styles.upper}>
            <div>
              <div className={styles.m0Wrap}>
                {menu.main?.map((item, i) => (
                  <M0 content={item} key={i} i={i} />
                ))}
                {process.env.GATSBY_DEFAULT_LOCALE === 'zh' && locale === 'zh' && (
                  <M0
                    content={{
                      data: {
                        title: 'EN',
                        path: '/en',
                      },
                    }}
                    addclassName={styles.lanDesktop}
                    partiallyActive={false}
                    bypassLocalization={true}
                    i={menu.main.length + 1}
                  />
                )}
                {process.env.GATSBY_DEFAULT_LOCALE === 'zh' && locale === 'en' && (
                  <M0
                    content={{
                      data: {
                        title: '中文',
                        path: '/',
                      },
                    }}
                    addclassName={styles.lanDesktop}
                    partiallyActive={false}
                    bypassLocalization={true}
                    i={menu.main.length + 1}
                  />
                )}
                {process.env.GATSBY_DEFAULT_LOCALE === 'en' && locale === 'en' && (
                  <M0
                    content={{
                      data: {
                        title: '中文',
                        path: '/zh',
                      },
                    }}
                    addclassName={styles.lanDesktop}
                    partiallyActive={false}
                    bypassLocalization={true}
                    i={menu.main.length + 1}
                  />
                )}
                {process.env.GATSBY_DEFAULT_LOCALE === 'en' && locale === 'zh' && (
                  <M0
                    content={{
                      data: {
                        title: 'EN',
                        path: '/',
                      },
                    }}
                    addclassName={styles.lanDesktop}
                    partiallyActive={false}
                    bypassLocalization={true}
                    i={menu.main.length + 1}
                  />
                )}
              </div>
              {/* <div
                className={`${styles.social} fade-in up stagger-${lastItemStagger}`}
              >
                <Social version='light' locale={locale} />
              </div> */}
            </div>
          </div>
          <div
            className={`${styles.lanWrap} fade-in up stagger-${lastItemStagger}`}
          >
            <Lan locale={locale} />
          </div>
        </div>
      </Inview>
    </>
  )
}

const Lan = ({ locale }) => {
  return (
    <div className={styles.lan}>
      <div className={styles.inner}>
        {process.env.GATSBY_DEFAULT_LOCALE === 'zh' && locale === 'zh' && (
          <ButtonLink
            content={{
              text: 'EN',
              to: '/en',
              linkType: 'page',
            }}
            bypassLocalization={true}
            theme='dark'
          />
        )}
        {process.env.GATSBY_DEFAULT_LOCALE === 'zh' && locale === 'en' && (
          <ButtonLink
            content={{
              text: '中文',
              to: '/',
              linkType: 'page',
            }}
            bypassLocalization={true}
            theme='dark'
          />
        )}
        {process.env.GATSBY_DEFAULT_LOCALE === 'en' && locale === 'en' && (
          <ButtonLink
            content={{
              text: '中文',
              to: '/zh',
              linkType: 'page',
            }}
            bypassLocalization={true}
            theme='dark'
          />
        )}
        {process.env.GATSBY_DEFAULT_LOCALE === 'en' && locale === 'zh' && (
          <ButtonLink
            content={{
              text: 'EN',
              to: '/',
              linkType: 'page',
            }}
            bypassLocalization={true}
            theme='dark'
          />
        )}
      </div>
    </div>
  )
}
const M0 = ({
  content,
  i,
  addClass = '',
  partiallyActive = true,
  bypassLocalization = false,
}) => {
  const [showActive, setShowActive] = useState(false)
  const toggleActiveState = () => setShowActive(!showActive)
  const { submenu, altTitle, data } = content
  const { title, path } = data || {}
  const setTitle = altTitle ? altTitle : title

  // workaround for no page at /products/

  const resetActiveState = (location) => {
    const pathMatch = location.pathname.includes(path)
    // const forceActive = location.pathname.includes('product') && path.includes('product')
    const forceActive = false
    const newShowActive = pathMatch || forceActive
    setTimeout(() => setShowActive(newShowActive), 1000)
  }
  const location = useLocation() // original location on first load
  useEffect(() => {
    resetActiveState(location) // original location on first load
    return globalHistory.listen((props) => {
      const { location, action } = props
      if (action === 'PUSH') {
        resetActiveState(location) // location from router after update
      }
    })
  }, [])

  const showActiveClass = showActive ? styles.showActive : ''
  // const forceActive = location.pathname.includes('product') && path.includes('product')
  const forceActive = false

  return (
    <div
      className={`${styles.m0} fade-in up stagger-${
        i * 50 + 100
      } ${addClass} ${showActiveClass}`}
    >
      <h4>
        {submenu && (
          <div className={styles.iconWrap} onClick={() => toggleActiveState()}>
            <IconExpand isOpen={showActive} theme='dark' />
          </div>
        )}
        <LineLink
          content={{ text: setTitle, to: path, linkType: 'page' }}
          customStyle='m0'
          forceActive={forceActive}
          partiallyActive={partiallyActive}
          bypassLocalization={bypassLocalization}
        />
      </h4>
      {submenu && (
        <div className={styles.submenu}>
          {submenu.map((item, i) => (
            <M1 content={item} key={i} />
          ))}
        </div>
      )}
    </div>
  )
}

const M1 = ({ content }) => {
  const { data } = content
  const { title, path } = data || {}
  return (
    <div className={styles.m1}>
      <p>
        <LineLink
          content={{ text: title, to: path, linkType: 'page' }}
          customStyle='m1'
          // activeClassName='active'
        />
      </p>
    </div>
  )
}

export default function Data({ layoutProps }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          menus: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: { templateKey: { eq: "menu" }, slug: { eq: "primary" } }
              }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  main {
                    page
                    altTitle
                    submenu {
                      page
                    }
                  }
                }
                zh {
                  title
                  main {
                    page
                    altTitle
                    submenu {
                      page
                    }
                  }
                }
              }
            }
          }
          pages: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "page" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  path
                }
                zh {
                  slug
                  title
                  path
                }
              }
            }
          }
          locations: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "location" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                }
                zh {
                  slug
                  title
                }
              }
            }
          }
          productCats: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "productCat" } } }
            }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                }
                zh {
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => <Component data={data} layoutProps={layoutProps} />}
    />
  )
}

// submenu {
//   page
// }
