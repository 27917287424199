import React from 'react'
import { useLocalization, LocalizedLink } from 'gatsby-theme-i18n'
import { Link } from 'gatsby'

const LinkWrap = ({
  to,
  className = '',
  activeClassName,
  partiallyActive,
  bypassLocalization,
  children,
}) => {
  const { locale } = useLocalization()

  if (to) {
    let setTo = to
    if (to.endsWith('/') && to !== '/') {
      setTo = to.slice(0, to.length - 1)
    }
    if (bypassLocalization) {
      return (
        <Link
          to={setTo}
          className={className}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
        >
          {children}
        </Link>
      )
    } else if (
      setTo === '/' &&
      locale === 'en' &&
      process.env.GATSBY_DEFAULT_LOCALE === 'zh'
    ) {
      // logo
      setTo = '/en'
      return (
        <Link to={setTo} className={className} partiallyActive={false}>
          {children}
        </Link>
      )
    } else if (
      setTo === '/' &&
      locale === 'zh' &&
      process.env.GATSBY_DEFAULT_LOCALE === 'en'
    ) {
      // logo
      setTo = '/zh'
      return (
        <Link to={setTo} className={className} partiallyActive={false}>
          {children}
        </Link>
      )
    } else {
      return (
        <LocalizedLink
          to={setTo}
          className={className}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
        >
          {children}
        </LocalizedLink>
      )
    }
  } else {
    return <a className={`empty ${className}`}>{children}</a>
  }
}

export default LinkWrap
