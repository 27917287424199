import React from 'react'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ layoutProps }) => {
  const { isMenuOpen, isHeaderUp, toggleIsMenuOpen, isHeaderDark } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''
  const headerUpClass = isHeaderUp ? styles.headerUp : ''
  const headerDarkClass = isHeaderDark ? styles.headerThemeDark : ''

  return (
    <div className={`${styles.thumbWrap} ${menuOpenClass} ${headerUpClass}`}>
      <Inview
        className={`${styles.thumb} ${menuOpenClass} ${headerDarkClass} fade-in`}
        onClick={toggleIsMenuOpen}
      >
        <span />
        <span />
        <span />
      </Inview>
    </div>
  )
}

export default Component
