import React, { useState, useEffect, useRef } from 'react'
import { globalHistory } from '@reach/router'
import Header from 'components/Header'
import Menu from 'components/Menu'
import MenuThumb from 'components/MenuThumb'
import * as styles from './styles.module.scss'

const Layout = (props) => {
  const { children, pageContext } = props
  const { locale } = pageContext
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isHeaderUp, setIsHeaderUp] = useState(false)
  const [isHeaderDark, setIsHeaderDark] = useState(false)
  const [isHeaderBgOn, setIsHeaderBgOn] = useState(false)

  const toggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const lastScrollY = useRef()
  const smartMenu = () => {
    const scrollDistance = window.scrollY - lastScrollY.current
    const hitBottom =
      window.scrollY + window.innerHeight >
      document.querySelector('body').offsetHeight - 10
    if (window.scrollY > 400 && scrollDistance > 0 && !hitBottom) {
      setIsHeaderUp(true)
    } else {
      setIsHeaderUp(false)
    }
    lastScrollY.current = window.scrollY
  }

  useEffect(() => {
    window.addEventListener('scroll', smartMenu, { passive: true })
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setIsMenuOpen(false)
      }
    })
  }, [setIsMenuOpen])

  const layoutProps = {
    isMenuOpen,
    toggleIsMenuOpen,
    isHeaderUp,
    setIsHeaderUp,
    isHeaderDark,
    setIsHeaderDark,
    isHeaderBgOn,
    setIsHeaderBgOn,
  }

  return (
    <div className={`${styles.component} lan-${locale}`}>
      <Header layoutProps={layoutProps} />
      <MenuThumb layoutProps={layoutProps} locale={locale} />
      <Menu layoutProps={layoutProps} />
      {React.cloneElement(children, { layoutProps })}
    </div>
  )
}

export default Layout
