import React from 'react'
import LinkWrap from 'components/LinkWrap'
import LogoSvg from 'assets/svg/vi/logo.svg'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ layoutProps }) => {
  const {
    isMenuOpen,
    isHeaderUp,
    isHeaderDark,
    isHeaderBgOn,
    setIsHeaderBgOn,
  } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''
  const headerUpClass = isHeaderUp ? styles.headerUp : ''
  const headerDarkClass = isHeaderDark ? styles.headerThemeDark : ''
  const headerBgOnClass = isHeaderBgOn ? styles.headerBgOn : ''
  return (
    <div
      id='header'
      className={`${styles.header} ${menuOpenClass} ${headerUpClass} ${headerDarkClass} ${headerBgOnClass}`}
      onMouseEnter={() => setIsHeaderBgOn(true)}
      onMouseLeave={() => setIsHeaderBgOn(false)}
    >
      <div className={`${styles.headerHiddenWrap}`}>
        <Inview className={`${styles.inner} fade-in`}>
          <div className={styles.bg} />
          <LinkWrap to='/' className={styles.logo}>
            <div className={styles.standard}>
              <LogoSvg />
            </div>
          </LinkWrap>
        </Inview>
      </div>
    </div>
  )
}

export default Component
