// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-types-investor-post-index-js": () => import("./../../../src/contentTypes/investorPost/index.js" /* webpackChunkName: "component---src-content-types-investor-post-index-js" */),
  "component---src-content-types-location-index-js": () => import("./../../../src/contentTypes/location/index.js" /* webpackChunkName: "component---src-content-types-location-index-js" */),
  "component---src-content-types-news-post-index-js": () => import("./../../../src/contentTypes/newsPost/index.js" /* webpackChunkName: "component---src-content-types-news-post-index-js" */),
  "component---src-content-types-page-index-js": () => import("./../../../src/contentTypes/page/index.js" /* webpackChunkName: "component---src-content-types-page-index-js" */),
  "component---src-content-types-product-cat-index-js": () => import("./../../../src/contentTypes/productCat/index.js" /* webpackChunkName: "component---src-content-types-product-cat-index-js" */),
  "component---src-content-types-product-index-js": () => import("./../../../src/contentTypes/product/index.js" /* webpackChunkName: "component---src-content-types-product-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

