import React from 'react'
import * as styles from './styles.module.scss'

const Icon = ({ isOpen, theme, onClick }) => {
  const isOpenClass = isOpen ? styles.isOpen : ''
  const themeClass = theme === 'dark' ? styles.themeDark : ''
  return (
    <div
      className={`${styles.icon} ${isOpenClass} ${themeClass}`}
      onClick={onClick}
    />
  )
}

export default Icon
