// extracted by mini-css-extract-plugin
export var bg = "styles-module--bg--elhOv";
export var header = "styles-module--header--a+NL6";
export var headerBgOn = "styles-module--header-bg-on--pxMii";
export var headerHiddenWrap = "styles-module--header-hidden-wrap--mgKVK";
export var headerThemeDark = "styles-module--header-theme-dark--r6gqu";
export var headerUp = "styles-module--header-up--kZhuR";
export var inner = "styles-module--inner--1joE8";
export var logo = "styles-module--logo--Zb2XA";
export var menuOpen = "styles-module--menu-open--nytUa";
export var standard = "styles-module--standard--+flNT";